import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import AppBar from "../src/components/Appbar";
import LoginPage from "./containers/LoginPage";
import OptimizeCvPage from "./containers/OptimizeCvPage";
import LandingPage from "./containers/LandingPage";
import SubscriptionPage from "./containers/SubscriptionPage";
import { Box, CssBaseline, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import TermsAndConditions from "./containers/TermsPage";
import PrivacyPolicy from "./containers/PrivacyPage";
import PaymentSuccessPage from "./containers/PaymentSuccessPage";
import BuildCvPage from "./containers/BuildCvPage";
import Loading from "./components/Loading";
import FAQPage from "./containers/FAQPage";
import ContactPage from "./containers/ContactPage";
import { UserProvider } from "./context/UserContext";
import LearnMorePage from "./containers/LearnMorePage";
import Footer from "./components/Footer";
import RefundPolicy from "./containers/RefundPolicy";

// ProtectedRoute Component
const ProtectedRoute = ({ user, children }) => {
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // Load the saved theme mode from localStorage or default to system preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const savedMode = localStorage.getItem('themeMode');
  const initialMode = savedMode || (prefersDarkMode ? 'dark' : 'light');
  const [mode, setMode] = useState(initialMode);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? "#ef709b" : "#ef709b",
      },
      background: {
        default: mode === 'light' ? "#f5f5f5" : "#121212",
        paper: mode === 'light' ? "#ffffff" : "#1e1e1e",
      },
    },
    typography: {
      fontFamily: "Arial, sans-serif",
    },
  });
  const getCustomerId = async (email) => {
    setLoading(true); // Set loading to true before starting the fetch
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-customer-id`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }), // Send email to backend
      });

      if (!response.ok) throw new Error(`Error fetching customer ID: ${response.statusText}`);
      const data = await response.json();
      console.log(data.customerId);
      setCustomerId(data.customerId);
    } catch (error) {
      console.error("Error fetching customer ID:", error +'for '+email);
    } finally {
      setLoading(false); // Set loading to false after the fetch is complete
    }
  };
  const checkSubscription = async (userId, customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          customerId,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Subscription status:", data);
  
      // Optionally, use response data to redirect users or update UI
    } catch (error) {
      console.error("Error checking subscription:", error.message);
    }
  };
  
  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newMode); // Save the new mode to localStorage
      return newMode;
    });

    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {

    if (isDarkMode) {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    } else {
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    }


    if (user) {
      if (customerId) {
        checkSubscription(user.uid, customerId);
      }
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        console.log(user.email);
        // If user email exists, fetch customerId if not loaded
        if (user.email && !customerId) {
          getCustomerId(user.email);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();

  }, [navigate, isDarkMode,customerId, user]);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Loading />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <UserProvider>
          <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar toggleTheme={toggleTheme} mode={mode} />
            <Box component="main" sx={{ flexGrow: 1, paddingTop: { xs: '56px', sm: '64px' } }}>
              <Routes>
                <Route path="/" element={<Navigate to={"/home"} />} />
                <Route path="/login" element={user ? <Navigate to="/home" /> : <LoginPage />} />
                <Route path="/home" element={<LandingPage />} />
                <Route
                  path="/optimize"
                  element={
                    <ProtectedRoute user={user}>
                      <OptimizeCvPage user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/upgrade"
                  element={
                    <ProtectedRoute user={user}>
                      <SubscriptionPage user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/makecv"
                  element={
                    <ProtectedRoute user={user}>
                      <BuildCvPage user={user} theme={theme} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/success"
                  element={
                    <ProtectedRoute user={user}>
                      <PaymentSuccessPage user={user} />
                    </ProtectedRoute>
                  }
                />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/t&c" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="learn-more" element={<LearnMorePage/>}/>
                <Route path="refund-policy" element={<RefundPolicy/>}/>
              </Routes>
              <Footer/>
            </Box>
          </Box>
        </UserProvider>
    </ThemeProvider>
  );
}

export default App;