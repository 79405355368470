import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(4, 0),
  marginTop: 'auto',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#666',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mb: { xs: 2, sm: 0 } }}
          >
            © {currentYear} Nexa Nest LTD. All rights reserved.
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              gap: { xs: 1, sm: 2 },
            }}
          >
            <FooterLink href="/privacy-policy">
              Privacy Policy
            </FooterLink>
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
              }}
            >
              |
            </Box>
            <FooterLink href="/t&c">
              Terms & Conditions
            </FooterLink>
          </Box>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;