import React from 'react';
import { 
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Box,
  Container
} from '@mui/material';
import { 
  CheckCircleOutline as CheckCircleOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';

// Custom MotionTypography component
const MotionTypography = motion(Typography);
// Custom MotionBox component
const MotionBox = motion(Box);
const MotionContainer = motion(Container);
const RefundPolicy = () => {
  return (
  <MotionContainer
    maxWidth="md"
    sx={{marginTop:2,marginBottom:2}}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
      <MotionTypography
        variant="h4"
        gutterBottom
        fontWeight="bold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        Refund Policy
      </MotionTypography>
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <Typography variant="body1" paragraph>
          At CVOptimizer, we are committed to delivering exceptional value and high-quality CV creation services. Our refund policy is designed to protect both our users and our service.
        </Typography>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          No-Refund Policy
        </Typography>
        <Typography variant="body1" paragraph>
          CVOptimizer operates a strict no-refund policy for the following reasons:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Immediate Digital Access"
              secondary="Our AI-powered CV building tools provide instant access to digital content and services."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Consumable Digital Service"
              secondary="Once you've accessed our CV generation tools, the service has been fully consumed."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Continuous Service Improvement"
              secondary="Refunds are not offered to ensure continuous development and enhancement of our AI technologies."
            />
          </ListItem>
        </List>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Pre-Purchase Recommendations
        </Typography>
        <Typography variant="body1" paragraph>
          Before making a purchase, we strongly encourage users to:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <InfoOutlinedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary="Review Service Details"
              secondary="Carefully examine our service offerings, features, and capabilities."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoOutlinedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary="Contact Support"
              secondary="Reach out to our customer support team with any questions or concerns before purchasing."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoOutlinedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary="Understand Limitations"
              secondary="Recognize that while we strive for excellence, CV creation is a collaborative process."
            />
          </ListItem>
        </List>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Exceptional Circumstances
        </Typography>
        <Typography variant="body1" paragraph>
          While our policy is typically non-negotiable, we may consider exceptional circumstances such as:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <ReportProblemOutlinedIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary="Technical Malfunction"
              secondary="Significant service disruptions preventing CV creation"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ReportProblemOutlinedIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary="Billing Errors"
              secondary="Unauthorized or incorrect charges"
            />
          </ListItem>
        </List>

        <Typography variant="body1" sx={{ mt: 2, fontStyle: 'italic', color: 'text.secondary' }}>
          By proceeding with a purchase, you acknowledge and agree to these terms. We appreciate your understanding and look forward to supporting your career development journey.
        </Typography>
      </MotionBox>
    </Paper>
   </MotionContainer>
  );
};

export default RefundPolicy;