import { doc, setDoc, getDoc  } from "firebase/firestore";
import CryptoJS from "crypto-js";
import { firestore } from '../../firebase';

// Generate a secure encryption key
const generateSecureKey = () => {
  return CryptoJS.lib.WordArray.random(32).toString(); // 256-bit key
};

// Generate a secure IV
const generateSecureIV = () => {
  return CryptoJS.lib.WordArray.random(16).toString(); // 128-bit IV
};

// Function to encrypt data with improved error handling
export const encryptData = (data) => {
  try {
    // Ensure data is a string
    const dataString = typeof data === 'object' ? JSON.stringify(data) : data.toString();

    // Use dynamically generated secure key and IV
    const key = CryptoJS.enc.Hex.parse(generateSecureKey());
    const iv = CryptoJS.enc.Hex.parse(generateSecureIV());

    const encrypted = CryptoJS.AES.encrypt(dataString, key, { 
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return { 
      encryptedData: encrypted.toString(), 
      key: key.toString(), 
      iv: iv.toString() 
    };
  } catch (error) {
    console.error("Encryption error:", error);
    throw new Error(`Encryption failed: ${error.message}`);
  }
};

// Function to decrypt data with improved error handling
export const decryptData = (encryptedData, key, iv) => {
  try {
    const parsedKey = CryptoJS.enc.Hex.parse(key);
    const parsedIv = CryptoJS.enc.Hex.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(encryptedData, parsedKey, { 
      iv: parsedIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    
    // Try to parse back to object if possible
    try {
      return JSON.parse(decryptedString);
    } catch {
      return decryptedString;
    }
  } catch (error) {
    console.error("Decryption error:", error);
    throw new Error(`Decryption failed: ${error.message}`);
  }
};

// Function to save CV data in Firestore with improved error handling
export const saveCvData = async (userId, cvData,complete,activeStep) => {
  try {
    // Validate input
    if (!userId || !cvData) {
      throw new Error("User ID and CV data are required");
    }

    // Encrypt the entire CV data
    const encryptedCv = encryptData(cvData);
    
    const cvRef = doc(firestore, "users", userId);
    await setDoc(cvRef, { resume: encryptedCv, copmlete: complete,activeStep:activeStep }, { merge: true });
    
    console.log("CV data saved successfully!");
    return true;
  } catch (error) {
    console.error("Error saving CV data:", error);
    throw error; // Re-throw to allow caller to handle
  }
};



export const getUserDataByUid = async (uid) => {
  try {
    // Step 1: Get the reference to the user's document
    const userDocRef = doc(firestore, "users", uid);
    // Step 2: Fetch the document for the specific user by their UID
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("No user data found for this UID");
    }

    // Return the user data if it exists
    const userData = userDoc.data();
    return userData;
  } catch (error) {
    console.error("Error fetching user data:", error.message);
    throw error;
  }
};

export const checkSubscriptionStatus = async (uid) => {
  try {
    // Step 1: Get the reference to the user's document
    const userDocRef = doc(firestore, "users", uid);

    // Step 2: Fetch the user document
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("No user data found for this UID");
    }

    const userData = userDoc.data();

    // Step 3: Extract subscription end date and check if it's available
    const subscriptionEndDate = userData.subscriptionEndDate;

    if (!subscriptionEndDate) {
      throw new Error("No subscription end date found for this user");
    }

    // Step 4: Calculate the difference between current date and subscription end date
    const currentDate = new Date();
    const endDate = new Date(subscriptionEndDate);
    const timeLeftInMillis = endDate - currentDate;

    // Step 5: Convert the time left into days
    const timeLeftInDays = Math.ceil(timeLeftInMillis / (1000 * 3600 * 24));

    // Step 6: Return the result
    if (timeLeftInDays > 0) {
      return `Your subscription will expire in ${timeLeftInDays} day(s).`;
    } else if (timeLeftInDays === 0) {
      return "Your subscription expires today.";
    } else {
      return "Your subscription has already expired.";
    }

  } catch (error) {
    console.error("Error checking subscription status:", error.message);
    throw error;
  }
};