// fileUtils.js
import mammoth from 'mammoth';
import pdfToText from 'react-pdftotext';

/**
 * Extracts text from a PDF file.
 * @param {File} file - The uploaded PDF file.
 * @returns {Promise<string>} - Extracted text from the PDF.
 */
export const extractTextFromPDF = async (file) => {
  try {
    const text = await pdfToText(file);
    return text;
  } catch (error) {
    throw new Error("Failed to extract text from PDF: " + error.message);
  }
};

/**
 * Extracts text from a DOCX file.
 * @param {File} file - The uploaded DOCX file.
 * @returns {Promise<string>} - Extracted text from the DOCX file.
 */
export const extractTextFromDOCX = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const { value } = await mammoth.extractRawText({ arrayBuffer });
    return value;
  } catch (error) {
    throw new Error("Failed to extract text from DOCX: " + error.message);
  }
};

/**
 * Handles file upload and extracts text based on file type.
 * @param {File} uploadedFile - The uploaded file.
 * @returns {Promise<string>} - Extracted text from the file.
 */
export const handleFileProcessing = async (uploadedFile) => {
  if (!uploadedFile) throw new Error("No file uploaded.");

  const fileType = uploadedFile.type;
  if (fileType === 'application/pdf') {
    return await extractTextFromPDF(uploadedFile);
  } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return await extractTextFromDOCX(uploadedFile);
  } else {
    throw new Error('Unsupported file type. Please upload a PDF or DOCX file.');
  }
};
