import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText,
  Link,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));


const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h3" component="h1"color="primary" gutterBottom mt={2} sx={{fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }}}>
        Privacy Policy
      </Typography>
      <Divider/>
      <Typography variant="subtitle1" gutterBottom>
        Last Updated: December 2024
      </Typography>

      <Section id="introduction">
        <SectionTitle variant="h4">Introduction</SectionTitle>
        <Typography paragraph>
          Welcome to CVOptimizer, a service provided by Nexa Nest LTD. This Privacy Policy explains how we collect, use, protect, and manage your personal information when you use our AI-powered CV creation platform. We are committed to protecting your privacy, and this policy outlines our practices for collecting, using, and safeguarding your information.
        </Typography>
      </Section>

      <Section id="information-we-collect">
        <SectionTitle variant="h4">1. Information We Collect</SectionTitle>
        <Typography paragraph>
          We collect the following types of personal information to help you create and optimize your curriculum vitae:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Personal Information: Name, email address, phone number" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Educational Background: School names, degrees, dates of attendance, locations, subjects studied" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Professional Experience: Company names, job positions, employment dates, locations, job descriptions" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Skills: Professional and technical skills" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Certifications: Professional certifications and achievements" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Projects: Details of professional or academic projects" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Languages: Languages spoken and proficiency levels" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Hobbies: Optional personal interests" />
          </ListItem>
          <ListItem>
            <ListItemText primary="References: Availability of professional references" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Profile Summary: A brief professional overview" />
          </ListItem>
        </List>
        <Typography paragraph>
          We collect this information to help you create an optimized CV based on your input.
        </Typography>
      </Section>

      <Section id="how-we-use-your-information">
        <SectionTitle variant="h4">2. How We Use Your Information</SectionTitle>
        <Typography paragraph>We use the collected information for the following purposes:</Typography>
        <List>
          <ListItem>
            <ListItemText primary="To generate and customize your CV" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To provide personalized AI-powered recommendations" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To improve our CV creation services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To communicate with you about your CV and our services" />
          </ListItem>
        </List>
        <Typography paragraph>
          Your data helps us offer a tailored CV-building experience to suit your professional background and goals.
        </Typography>
      </Section>

      <Section id="data-protection-and-security">
        <SectionTitle variant="h4">3. Data Protection and Security</SectionTitle>
        <Typography paragraph>We take the security of your personal information seriously. Here's how we protect your data:</Typography>
        <List>
          <ListItem>
            <ListItemText primary="Encryption: All user data, including your CV details, is encrypted before storage." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Security Protocols: We use industry-standard security protocols to prevent unauthorized access to your data." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Confidentiality: Your personal information is stored securely and kept confidential, with access limited to authorized personnel only." />
          </ListItem>
        </List>
        <Typography paragraph>
          We also regularly review our security practices to ensure we meet the highest standards.
        </Typography>
      </Section>

      <Section id="use-of-third-party-services">
        <SectionTitle variant="h4">4. Use of Third-Party Services</SectionTitle>
        <Typography paragraph>
          We use third-party services to provide certain functionality within the platform. These services may have access to your data as part of their operations. Here's an overview of the third-party services we use:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Stripe" 
              secondary="We use Stripe for payment processing. Stripe handles all transactions securely and in compliance with PCI DSS. For more information, visit Stripe's Privacy Policy. https://stripe.com/gb/privacy" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Firebase Authentication" 
              secondary="We use Firebase Authentication for managing user accounts. Firebase may process your email and password for authentication purposes. For more details, refer to the Firebase Privacy Policy. https://firebase.google.com/support/privacy" 
            />
          </ListItem>
        </List>
        <Typography paragraph>
          These services may process your data in accordance with their own privacy policies. We ensure that they comply with relevant privacy regulations and that they implement appropriate safeguards to protect your data.
        </Typography>
      </Section>

      <Section id="generative-ai-technology">
      <SectionTitle variant="h4">5. Generative AI Technology</SectionTitle>
      <Typography paragraph>
        Our CV generation leverages advanced generative AI technology developed by Meta and hosted on Groq's platform using the *Llama-3.3-70B-Versatile* model. For more information about data handling, please refer to Groq's <Link href="https://groq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
      </Typography>
      <Typography paragraph>
        The AI analyzes the information you provide to generate tailored CV content. Your data is processed securely and used only for CV creation and the improvement of our services.
      </Typography>
     </Section>


      <Section id="data-sharing-and-disclosure">
        <SectionTitle variant="h4">6. Data Sharing and Disclosure</SectionTitle>
        <Typography paragraph>
          We do not sell, rent, or trade your personal information to third parties. However, we may share anonymized or aggregated data with third parties for the purpose of improving our services, provided that this data cannot identify any individual.
        </Typography>
        <Typography paragraph>
          In addition, we may disclose your data to comply with legal obligations or in response to lawful requests by public authorities, such as a court order or government investigation.
        </Typography>
      </Section>

      <Section id="user-rights">
        <SectionTitle variant="h4">7. User Rights</SectionTitle>
        <Typography paragraph>You have the following rights concerning your personal information:</Typography>
        <List>
          <ListItem>
            <ListItemText primary="Access: You can request access to the personal information we hold about you." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Correction: You can request to update or correct any inaccurate or incomplete information." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Deletion: You can request the deletion of your account and personal information." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Opt-out: You can opt-out of receiving promotional communications from us." />
          </ListItem>
        </List>
        <Typography paragraph>
          To exercise these rights, please contact us at support@cvoptimizer.com.
        </Typography>
      </Section>

      <Section id="cookies-and-tracking">
        <SectionTitle variant="h4">8. Cookies and Tracking</SectionTitle>
        <Typography paragraph>
          We use cookies to enhance user experience and improve our service. Cookies are small text files stored on your device that help us analyze website traffic and customize content.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="You can manage cookie preferences in your browser settings, including opting out of non-essential cookies." />
          </ListItem>
          <ListItem>
            <ListItemText primary="For more detailed information, please refer to our Cookie Policy." />
          </ListItem>
        </List>
      </Section>

      <Section id="childrens-privacy">
        <SectionTitle variant="h4">9. Children's Privacy</SectionTitle>
        <Typography paragraph>
          Our service is not intended for children under the age of 16. We do not knowingly collect personal information from minors. If we learn that we have collected personal information from a child under 16, we will delete it as soon as possible.
        </Typography>
      </Section>

      <Section id="international-data-transfers">
        <SectionTitle variant="h4">10. International Data Transfers</SectionTitle>
        <Typography paragraph>
          Your personal information may be processed and stored in countries other than the one in which you reside. These countries may have different data protection laws. By using our service, you consent to the transfer of your data across borders in compliance with applicable privacy laws.
        </Typography>
      </Section>

      <Section id="changes-to-privacy-policy">
        <SectionTitle variant="h4">11. Changes to Privacy Policy</SectionTitle>
        <Typography paragraph>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated date. We encourage you to review this page periodically to stay informed about how we are protecting your information.
        </Typography>
      </Section>

      <Section id="contact-us">
        <SectionTitle variant="h4">12. Contact Us</SectionTitle>
        <Typography paragraph>For privacy-related inquiries or to exercise your data rights, please contact us at:</Typography>
        <Typography>Email: support@cvoptimizer.com</Typography>
        <Typography>Address: Nexa Nest LTD, London, United Kingdom</Typography>
      </Section>

      <Section id="compliance">
        <SectionTitle variant="h4">13. Compliance</SectionTitle>
        <Typography paragraph>
          This Privacy Policy complies with applicable data protection regulations, including the General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA). We are committed to ensuring that your personal information is handled responsibly and in compliance with these laws.
        </Typography>
      </Section>

      <Section id="conclusion">
        <SectionTitle variant="h4">14. Conclusion</SectionTitle>
        <Typography paragraph>
          At CVOptimizer, we are committed to protecting your privacy. We use advanced technologies like encryption and secure protocols to safeguard your information. Your personal data is used solely to enhance your experience with our AI-powered CV service, and we will never share it without your consent.
        </Typography>
      </Section>
    </Container>
  );
};

export default PrivacyPolicy;