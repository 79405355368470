import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Description,
  AlternateEmail,
  Email,
  CheckCircle,
  Timer,
  Star,
  Check,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '50%',
  background: 'linear-gradient(45deg, #ef709b 30%, #fa9372 90%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 1rem auto',
}));


const services = [
  {
    icon: <Description sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'AI-Optimized CV',
    description: 'Our AI-powered system analyzes job descriptions to create a CV that stands out to employers and applicant tracking systems.',
    details: [
      'Keyword optimization for specific job roles',
      'Tailored content to match industry standards',
      'Automatic formatting to highlight your strengths',
      'Suggestions for skills and experiences to include',
    ],
  },
  {
    icon: <AlternateEmail sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'Efficient CV Building',
    description: 'Create a professional CV quickly and easily with our intuitive tools and AI-assisted content generation.',
    details: [
      'Wide range of customizable templates',
      'AI-powered content suggestions',
      'Real-time preview and editing',
      'Easy import of existing CV data',
    ],
  },
  {
    icon: <Email sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'Tailored Cover Letters',
    description: 'Generate personalized cover letters that complement your CV and address specific job requirements.',
    details: [
      'AI-driven content customization for each application',
      'Integration with your CV data for consistency',
      'Tone and style adjustment to match company culture',
      'Highlighting of relevant skills and experiences',
    ],
  },
  {
    icon: <CheckCircle sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'ATS-Friendly Format',
    description: 'Ensure your CV gets past Applicant Tracking Systems and into the hands of hiring managers.',
    details: [
      'Optimized layouts for ATS compatibility',
      'Proper use of headers and sections',
      'Keyword placement strategy',
      'File format recommendations for different ATS',
    ],
  },
  {
    icon: <Timer sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'Quick Creation',
    description: 'Build a stunning CV in minutes with our streamlined process and user-friendly interface.',
    details: [
      'Step-by-step CV creation wizard',
      'Pre-filled sections based on your industry',
      'Rapid content generation with AI assistance',
      'Easy-to-use drag-and-drop interface',
    ],
  },
  {
    icon: <Star sx={{ color: '#fff', fontSize: 40 }} />,
    title: 'Expert Guidance',
    description: 'Receive AI-powered suggestions and expert tips to enhance your CV and improve your chances of landing interviews.',
    details: [
      'Industry-specific advice and best practices',
      'Personalized improvement suggestions',
      'Up-to-date trends in CV writing',
      'Feedback on content, structure, and formatting',
    ],
  },
];

export default function LearnMorePage() {
  return (
    <Box sx={{ minHeight: '100vh', py: 8 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: 6 , fontWeight: 'bold'}}
        >
          Our Services
        </Typography>
        {services.map((service, index) => (
          <StyledPaper key={index} elevation={3}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={3}>
                <IconWrapper>
                  {service.icon}
                </IconWrapper>
                <Typography
                  variant="h4"
                  component="h2"
                  align="center"
                  gutterBottom
                  sx={{ fontWeight: 'bold', mb: 2 }}
                >
                  {service.title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="body1" paragraph>
                  {service.description}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
                  Key Features:
                </Typography>
                <List>
                  {service.details.map((detail, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <Check sx={{ color: '#ff6b8a' }} />
                      </ListItemIcon>
                      <ListItemText primary={detail} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </StyledPaper>
        ))}
      </Container>
    </Box>
  );
}