import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const SubSection = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const TermsPage = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <StyledContainer maxWidth="md">
      <Typography variant="h2" component="h1" color='primary' gutterBottom sx={{fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }}}>
        Terms and Conditions
      </Typography>
      <Divider/>
      <SectionTitle variant="h4" component="h2">
        1. Introduction
      </SectionTitle>
      <Typography paragraph>
        Welcome to CVOptimizer, a service provided by Nexa Nest LTD. These Terms and Conditions govern your use of our AI-powered CV creation platform. By accessing or using our service, you agree to be bound by these terms.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        2. Governing Law
      </SectionTitle>
      <Typography paragraph>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising from the use of our service shall be subject to the exclusive jurisdiction of the courts of England and Wales, with specific reference to the laws of the United Kingdom.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        3. Privacy Policy
      </SectionTitle>
      <Typography paragraph>
        Our Privacy Policy is an integral part of these Terms and Conditions. Please review our comprehensive privacy policy at: <Link href="https://www.cvoptimizer.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.cvoptimizer.com/privacy-policy</Link>
      </Typography>

      <SectionTitle variant="h4" component="h2">
        4. Contact Information
      </SectionTitle>
      <Typography paragraph>
        For any questions, concerns, or support:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Email: support@cvoptimizer.com" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        5. Limitation of Liability
      </SectionTitle>
      <Typography paragraph>
        5.1 CVOptimizer provides services "as is" and makes no warranties about the accuracy or completeness of the CV generation process.
      </Typography>
      <Typography paragraph>
        5.2 Our total liability shall not exceed the amount paid by the user for the specific service.
      </Typography>
      <Typography paragraph>
        5.3 We are not liable for:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Any loss of job opportunities" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Indirect or consequential damages" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Any errors in CV generation" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Any misuse of the generated content by third parties" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        6. User Rules of Conduct
      </SectionTitle>
      <Typography paragraph>
        Users must:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Provide accurate and truthful information" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Use the service for lawful purposes only" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Not attempt to circumvent security measures" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Respect intellectual property rights" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Not share account credentials" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Not use the service to create offensive or illegal content" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        7. User Restrictions
      </SectionTitle>
      <Typography paragraph>
        Users are prohibited from:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Attempting to hack or disrupt our service" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Using automated scripts or bots" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Reproducing or distributing our AI-generated content without permission" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Creating multiple accounts to abuse service features" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Using the service for fraudulent purposes" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        8. Account Termination
      </SectionTitle>
      <SubSection variant="h6" component="h3">
        8.1 Users may terminate their account at any time by:
      </SubSection>
      <List>
        <ListItem>
          <ListItemText primary="Contacting customer support" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Using the account deletion option in user settings" />
        </ListItem>
      </List>
      <SubSection variant="h6" component="h3">
        8.2 CVOptimizer reserves the right to terminate accounts:
      </SubSection>
      <List>
        <ListItem>
          <ListItemText primary="For violation of these terms" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Suspicious account activity" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Non-compliance with user rules" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Repeated service misuse" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        9. Refund Policy
      </SectionTitle>
      <Typography paragraph>
      9.1 CVOptimizer maintains a strict no-refund policy.  Please refer to our 
      <Link href="https://www.cvoptimizer.com/refund-policy" target="_blank" rel="noopener noreferrer"> Refund Policy </Link>  page.

      </Typography>
      
      <Typography paragraph>
        9.2 Once a purchase is made and access to AI CV building tools is granted, no refunds will be issued.
      </Typography>
      <Typography paragraph>
        9.3 Users are encouraged to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Carefully review service offerings" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Contact customer support with any questions before purchase" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Understand the nature of digital content access" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        10. Intellectual Property
      </SectionTitle>
      <SubSection variant="h6" component="h3">
        10.1 CVOptimizer retains all intellectual property rights to:
      </SubSection>
      <List>
        <ListItem>
          <ListItemText primary="Platform design" />
        </ListItem>
        <ListItem>
          <ListItemText primary="AI algorithms" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Generated content templates" />
        </ListItem>
      </List>
      <Typography paragraph>
        10.2 Users own the content they input and the final CV generated.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        11. Service Modifications
      </SectionTitle>
      <Typography paragraph>
        We reserve the right to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Modify or discontinue services" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Update pricing" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Change terms and conditions" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Implement new features or remove existing ones" />
        </ListItem>
      </List>

      <SectionTitle variant="h4" component="h2">
        12. User Data and Content
      </SectionTitle>
      <Typography paragraph>
        12.1 Users grant CVOptimizer a non-exclusive license to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Process user-provided information" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Generate and store CV content" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Provide requested services" />
        </ListItem>
      </List>
      <Typography paragraph>
        12.2 Users are responsible for the accuracy of submitted information.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        13. Third-Party Links and Services
      </SectionTitle>
      <Typography paragraph>
        13.1 Our platform may contain links to third-party websites.
      </Typography>
      <Typography paragraph>
        13.2 We are not responsible for third-party content or practices.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        14. Disclaimer of Warranties
      </SectionTitle>
      <Typography paragraph>
        14.1 Services are provided "as is" without any guarantees.
      </Typography>
      <Typography paragraph>
        14.2 We do not guarantee job placement or interview success.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        15. Severability
      </SectionTitle>
      <Typography paragraph>
        If any provision of these terms is found to be invalid, the remaining provisions shall continue in full force.
      </Typography>

      <SectionTitle variant="h4" component="h2">
        16. Entire Agreement
      </SectionTitle>
      <Typography paragraph>
        These Terms and Conditions constitute the entire agreement between the user and CVOptimizer.
      </Typography>

      <Divider sx={{ my: 4 }} />

      <Typography variant="body2" color="text.secondary" align="right">
        Last Updated: {currentDate}
      </Typography>
    </StyledContainer>
  );
};

export default TermsPage;